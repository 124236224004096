<template>
  <div class="w-full">
    <div class="w-full flex flex-col px-6 justify-center bg-white shadow-md">
      <div class="content container">
        <nav-bar :email="$route.query.email" />
      </div>
    </div>

    <div v-if="loading" class="w-full flex flex-col justify-center items-center mt-4">
      <span class="fa fa-spinner fa-spin text-lg text-secondary"></span>
    </div>

    <div v-if="!loading" class="w-full h-full flex flex-col items-center" :class="modal ? 'blur' : ''">
      <div class="wrapper">
        <div class="content container p-10">
          <h1 class="text-secondary text-center text-primary text-2xl mt-6">RECEBEMOS SEU INTERESSE EM UMA NOVA PROPOSTA
          </h1>
          <p class="mt-4 text-lg">Em breve, um de nossos consultores entrará em contato com mais informações.</p>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import NavBar from '@/components/Landing/NavBar/NavBar'
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal'
import Footer from '@/components/Footer/Footer.vue'
import CarouselItems from '@/components/Carousel/Carousel.vue'
import Simulation from '@/services/Landing/Simulation'
import VInput from '@/components/Inputs/Input'

export default {
  name: 'investor',
  components: { VInput, NavBar, ChooseLoginModal, Footer, CarouselItems, Simulation },
  data() {
    return {
      modal: false,
      loading: false,
      mensagem: '',
      email: '',
    }
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        await Simulation.interestProposalGuarantee(this.$route.params.uId);


        const self = this;


      } catch (error) {
        this.$store.dispatch("notification", {
          type: "error",
          message: typeof (error.response.data.erros) == 'object' ? error.response.data.erros[Object.keys(error.response.data.erros)[0]] : error.response.data.erros.map(x => x[Object.keys(x)[0]])
        });
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.email = this.$route.query.email;
    await this.submit();
  }
}
</script>
<style>
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>